.nv__contact__section{
    width: 100%;
    height: 800px;
    background: url("../../../assets/images/home/contact/contact-image-1-full-test.jpeg");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .nv__overlay{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__info{
        width: 70%;
        height: auto;
        margin: 0 auto;
    }
    

    h1{
        font-size: 50px;
        line-height: 60px;
        text-align: center;
        color: $white-color;
    }

    .nv__button{
        margin: 0 auto;
        display: block;
        margin-top: 30px;
    }
}

@media screen and (max-width: 1170px){
    .nv__contact__section{
        .nv__section__container{
            width: 95%;
        }
    }
}

@media screen and (max-width: 992px){
    .nv__contact__section{
        height: 600px;

        .nv__section__container{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center ;
        }

        &__info{
            padding-top: 0px;
        }


        h1{
            font-size: 30px;
            line-height: 40px;
        }
    }
}

@media screen and (max-width:768px){
    .nv__contact__section{
        height: 400px;

        &__info{
            width: 90%;
            padding-top: 0px;
        }

        h1{
            font-size: 24px;
            line-height: 32px;
        }
    }
}