html {
	font-family: $theme;
	font-size: 16px;
	line-height: 20px;
	color: $black-color;
}

body {
	font-family: $theme;
	font-size: 16px;
	line-height: 20px;
	color: $black-color;
	background-color: $strong-blue-color;
}

* {
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;
	color: $black-color;
}

p {
	width: 100%;
	height: auto;
	@include fontsTitles($theme, 16px, 16px);
}



img {
	width: 100%;
	height: auto;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}

::-moz-selection {
	color: $white-color;
	background: $blue-color;
}
::selection {
	color: $white-color;
	background: $blue-color;
}

.overflow-hidden {
	overflow: hidden;
}

.relative {
	position: relative;
}

.nv__overlay{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2;

	&--dark-5{
		background-color: rgba(0, 0, 0, 0.5);
	}

	&--dark-4{
		background-color: rgba(0, 0, 0, 0.4);
	}

	&--dark-3{
		background-color: rgba(0, 0, 0, 0.3);
	}

}

.clearboth {
	clear: both;
}

.nv__shading__layer{
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 70%);
    pointer-events: none;
}


.visibility{
	&--hidden {
		visibility: hidden;
	}
}

.wrapper{

	&--full{
		width: 100%;
		height: 100%;
	}
}
/* --------------------------------------------------------
/* ---- EFFECTS & OTHERS
-------------------------------------------------------- */

.transition {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}
