.nv__compromise{
    width: 100%;
    height: auto;
    background-color: $strong-blue-color;
    color: $white-color;

    .nv__section__container{
        display: flex;
    }
    &__block{
        width: 50%;
        height: auto;
        
        &--text{
            width: 50%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .nv__compromise__info{
            width: 70%;
            height: auto;

            h4{
                font-size: 18px;
                line-height: 100%;
                margin-bottom: 20px;
            }

            p{
                text-align: justify;
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 30px;
            }
        }

        &--image{
            width: 50%;
            height: 700px;
            background-size: cover !important;
            background-position: center center !important;
        }
    }   
}

@media screen and (max-width: 1170px){
    .nv__compromise{
        
        .nv__section__container{
            width: 100%;
        }
    }
}

@media screen and (max-width: 992px){
    .nv__compromise{
        padding: 40px 0px 40px 0px;
        .nv__section__container{
            display: block;
        }

        .nv__compromise__info{
            width: 80%;
        }
        &__block{
            width: 100%;
            height: auto;
            &--text{
                width: 100%;
            }

            &--image{
                width: 80%;
                height: 600px;
                margin: 0 auto;
            }
        }
    }
}

@media screen and (max-width: 680px){
    .nv__compromise{
        
        &__block{
            &--image{
                width: 80%;
                height: 400px;
                margin: 0 auto;
            }
        }

        .nv__compromise__info{
           

            h4{
                font-size: 16px;
            }

            p{
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
}