$blue-color: #00649A;
$strong-blue-color: #003551;
$white-color: #ffffff;
$black-color: #000000;
$strong-gray-color:#1E1E1E;
$gray-color: #F7F7F7;
$theme: "Montserrat-Regular";

// Paleta de colores
$colors: (
"blue-color": $blue-color,
"strong-blue-color": $strong-blue-color,
"white-color": $white-color,
"black-color": $black-color, 
"gray-color": $gray-color, 
"strong-gray-color": $strong-gray-color
);
