.nv__politics{
    width: 100%;
    height: auto;
    padding-top: 120px;
    color: $black-color;

    &__picture{
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }

    h4{
        font-size: 20px;
        line-height: 30px;
        color: $white-color;
        font-family: "Montserrat-Regular";
    }

    p{
        font-size: 18px;
        line-height: 28px;
        text-align: justify;
        margin-bottom: 20px;
        color:  $black-color;
    }

    ul{
        li{
            font-size: 18px;
            line-height: 28px;
            text-align: justify;
            margin-bottom: 20px;
            color:  $black-color;
            font-family: "Montserrat-Regular";
        }
    }


    &__item{
        margin-bottom: 30px;
    }
    
}


@media screen and (max-width: 992px){
    .nv__politics{
        padding-top: 40px;
        p{
            font-size: 16px;
            line-height: 26px;
        }

        h4{
            font-size: 18px;
            line-height: 28px;
        }

        ul{
            li{
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}