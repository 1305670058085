.nv__button{
    width: 180px;
    height: auto;
    border: 0px;
    padding: 14px 0px 14px 0px;
    font-size: 16px;
    cursor: pointer;
    color: $white-color;
    background-color: $blue-color;
    

    &--rounded{
        border-radius: 25px;
        background: transparent !important;
    }

    &--rounded--clear{
        border-radius: 25px;
        background-color: transparent;
        color: $blue-color;
        border: 1px solid $blue-color;

        &:hover{
            color: $white-color;
            background-color: $strong-blue-color;
        }
    }

    &:hover{
        background-color: $strong-blue-color;
    }


}