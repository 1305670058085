.nv__blog{
    width: 100%;
    height: auto;

    .nv__button{
        display: block;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    h1{
        text-transform: capitalize;
    }
    &__header{
        width: 100%;
        height: 100px;
        background-color: $black-color;
        margin-bottom: 40px;
    }
    &__container{
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 40px;
        align-items: stretch;
    }

    &__item{
        width: 32%;
        height: auto;
        margin-bottom: 30px;
        

        &__info{
            width:100%;
            height: 100%;
            margin: 0 auto;
            background-color: $white-color;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }

        &__image{
            width: 100%;
            height: auto;
            margin-bottom: 20px;
        }

        &__data{
            width: 90%;
            height: auto;
            margin: 0 auto;
            margin-bottom: 20px;
            padding-bottom: 30px;
        }

        span{
            font-size: 14px;
            color: #000;
            display: block;
        }

        h4{
            width: 100%;
            height: auto;
            margin-bottom: 14px;
            font-size:18px;
            line-height: 26px;
            font-weight: 800;
        }

        p{
            font-size: 14px;
            color: #000;
            line-height: 20px;
            margin-bottom: 40px;
            text-align: justify;
        }

    }

   
    
}

@media screen and (max-width: 1170px){
    .nv__blog{
        .nv__section__container{
            width: 95%;
        }
    }
}

@media screen and (max-width: 992px){
    .nv__blog{
        .nv__section__container{
            width: 95%;
        }

        &__item{

            &__data{

                padding-bottom: 10px;
            }
            h4{
                font-size: 14px;
            }
    
            p{
                font-size: 12px;
                margin-bottom: 30px;
            }  
        }
    }
}

@media screen and (max-width: 768px){
    .nv__blog{
        .nv__blog__container{
            flex-wrap: wrap;
        }
        &__item{
            width: 48%;
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width: 480px){
    
    .nv__blog{

        .nv__section__container{
            width: 80%;
        }

        
        &__item{
            width: 100%;
            margin-bottom: 30px;
        }
    }
}