/* --------------------------------------------------------
/* ----MARGINS BOTTOMS
-------------------------------------------------------- */
$marginNumber: 24;
@for $i from 1 through $marginNumber {
	.mb-#{$i*5} {
		margin-bottom: #{$i * 5}px;
	}
}

/* --------------------------------------------------------
/* ----MARGINS TOP
-------------------------------------------------------- */
@for $i from 1 through $marginNumber {
	.mt-#{$i*5} {
		margin-top: #{$i * 5}px;
	}
}
