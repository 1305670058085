.ant-drawer{
    svg{
        color: $white-color !important;
    }
}

.ant-drawer-content{
    color: $white-color !important;
    background-color: $black-color !important;
}

.ant-drawer-header-title{
    color: $white-color !important;
}

