.nv__language__selector{
    width: 40px;
    height: auto;
    cursor: pointer;
    position: relative;
    z-index: 999;
    margin-left: 10px;
    top: 3px; 
}

.linkedin__nav{
    margin-left: 10px;
    margin-right: 10px;

    svg{
        font-size: 30px;
        color: $white-color;
        position: relative;
        top: 3px
    };
}