/* --------------------------------------------------------
/* ---- TEXT MODIFIERS
-------------------------------------------------------- */

.text-uppercase {
	text-transform: uppercase;
}

.text-center {
	text-align: center;
}

.text-justify {
	text-align: justify;
}

.pointer {
	cursor: pointer;
}

.uppercase{
	text-transform: uppercase;
}