.flex{
    display: flex;
    &--all-center{
        justify-content: center;
        align-items: center;
    }

    &--space-between{
        justify-content: space-between;
        align-items: center;
    }
}