.dona__page{

    &__content{
        width: 800px;
        height: auto;

        .nv__slider__logo{
            width: 140px;
            margin: 0 auto;
            margin-bottom: 20px;
        }

        p{
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 20px;
            text-align: justify;
        }
    }
}

@media screen and (max-width: 992px){

    
    .dona__page{
         
        &__content{
            width: 90%; 
            margin: 0 auto;
            height: auto;
            min-height: auto;
            overflow-y: scroll;
            padding-top: 80px;
            
        }

        .nv__slider__logo{
            width: 100px;
        }
    }
}