.nv__timeline{
    width: 100%;
    height: auto;
    margin-bottom: 0px;
    padding: 120px 0;
    overflow: hidden;

    .nv__section__container{
        width: 100%;
        padding-bottom: 30px;
        
    }

    &__antesala{
        width: 1170px;
        height: auto;
        margin: 0 auto;
        margin-bottom: 60px;

        p{
            font-size: 16px;
            text-align: justify;
            line-height: 22px;
            margin-bottom: 30px;
        }
    }
    

    &__base{
        width: 80%;
        height: auto;
        margin: 0 auto;
        display: flex;
        justify-content: flex-start;
        overflow-x: scroll;
        align-items: center;
        height: auto;
        padding-bottom: 60px;

        /* Estilos para el scrollbar en navegadores basados en Webkit */
        &::-webkit-scrollbar {
            width: 12px;  
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $blue-color;
            border-radius: 6px; 
        }

        &::-webkit-scrollbar-track {
            background-color: lightgrey;
            border-radius: 6px; 
        }
            
    }
    span{
        display: block;
        margin: 0 auto;
        font-size: 14px;
    }

    h1{
        margin-bottom: 60px;
    }
    
    

    &__item{
        flex: 0 0 400px;
        width: 400px;
        height: auto;
        position: relative;
        padding-bottom: 80px ;
        border-bottom: 1px solid $black-color;
        padding-right: 80px;


        h3{
            font-size: 22px;
            margin-bottom: 20px;
        }

        p{
            height: 100px;
            text-align: justify;
            font-size: 16px;
            line-height: 22px;
        }
    }
    


    &__dots{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $white-color;
        border: 1px solid $strong-blue-color;
        position: relative;
        bottom: -95px;
        z-index: 2;
        
        &::before{
            content: '';
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $blue-color;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
@media screen and (max-width: 1170px){
    .nv__timeline{
        &__antesala{
            width: 80%;
            height: auto;
            margin: 0 auto;
            margin-bottom: 60px;
        }
    }
}

@media screen and (max-width: 992px){
    .nv__timeline{

        h1{
            margin-bottom: 40px;
        }

        &__base{
            width: 90%;
        
        }
    }
}

@media screen and (max-width: 480px){
    .nv__timeline{
        &__base{
            &::-webkit-scrollbar {
                width: 12px;  
                height: 12px;
            }
            
        }

        &__item{
            flex: 0 0 300px;
            width: 300px;
            height: auto;
        }
    }
}

