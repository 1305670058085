.nv__preload{
    width: 100%;
    height: 100vh;
    position: relative;
    background: url(../../../assets/slider/portrait.png);
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;


    p{
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 20px;
        text-align: justify;
        color: $white-color;
    }
    .nv__section{
        margin-bottom: 0px !important;
    }


    &__content{
        width: 800px;
        height: auto;
        margin: 0 auto;
    }   

    &__logo{
        width: 120px;
        height: auto;
        margin: 0 auto;
        margin-bottom: 20px;
    }



}


@media screen and (max-width: 992px){
    .nv__preload{
        min-height: 100vh;
        &__content{
            width: 80%;
            padding-top: 120px;
        }   


        &__footer__menu{
            display: none;
        }

        &__logo{
            width: 80px;
        }

        height: auto;
    }
}

@media screen and (max-width: 568px){
    .nv__preload{

        p{
            font-size: 14px;
            line-height: 24px;
        }
    }
}