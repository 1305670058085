.nv__acordeon{
    color:$white-color;

    span{
        color:$blue-color;
        font-size: 22px;
        line-height: 32px;
    }


}

.ant-collapse-header{
    background: $blue-color !important;
}
.ant-collapse-expand-icon{
    color:$white-color; 
    height: 30px !important;
    
}