@mixin fontsTitles($font, $size, $line) {
	font-family: $font;
	font-size: $size;
	line-height: $line;
}

@mixin sizeTitles($size, $line) {
	font-size: $size;
	line-height: $line;
}
