/* --------------------------------------------------------
/* ----PADDINGS BOTTOMS
-------------------------------------------------------- */
$marginNumber: 24;
@for $i from 1 through $marginNumber {
	.pb-#{$i*5} {
		padding-bottom: #{$i * 5}px;
	}
}

/* --------------------------------------------------------
/* ----PADDINGS TOP
-------------------------------------------------------- */
@for $i from 1 through $marginNumber {
	.pt-#{$i*5} {
		padding-top: #{$i * 5}px;
	}
}


@for $i from 1 through 100 {
	.pd-#{$i*10} {
		padding: #{$i * 10}px;
	}
}
 